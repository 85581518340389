import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import "./AddPartDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { Col, Input, message, Row, Modal } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";

const { TextArea } = Input;

function AddPartDialog(props) {
  const { show, proceed, partInfo, title, isEditing } = props

  const [selPart, setSelPart] = useState({});
  const [errorField, setErrorField] = useState([]);

  const [partDescription, setPartDescription] = useState('');
  
  useEffect(() => {
    if (isEditing && partInfo["description"] != undefined) {
      setPartDescription(partInfo["description"]);
    } 
  }, [])
  
  useEffect(() => {
    if (partInfo !== undefined) {
      setSelPart({ ...partInfo });
    } else {
      setSelPart({});
    }
  }, [partInfo]);

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(selPart, "url", errorList);
    errorList = isEmpty(selPart, "time_to_spend_on_site", errorList);
    errorList = isEmpty(selPart, "link_to_click_on", errorList);
    errorList = isEmpty(selPart, "how_many_links_deep", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  const onChangeText = (text) => {
    setPartDescription(text)
  };
  
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ]
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-part-dlg"
      onOk={async () => {

        if (isEditing === false && (await validateFields()) === false) return;
        selPart["description"] = partDescription
        setSelPart({ ...selPart });
        proceed({
          ...selPart,
        });
      }}
    >
      <div>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Url*</div>
          <ProductDescriptionInput
            placeholder="Enter url"
            field={"url"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Time to Spend on site*</div>
          <ProductInputNumber
            min={"0"}
            field={"time_to_spend_on_site"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16} style={{marginTop:10}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Link to click on*</div>
          <ProductDescriptionInput
            placeholder="Link to click on"
            field={"link_to_click_on"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <div>How many links deep*</div>
          <ProductInputNumber
            min={"0"}
            field={"how_many_links_deep"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div>Description*</div>
          <ReactQuill theme="snow" value={partDescription} onChange={onChangeText}
            className="edit-panel"
            modules={modules}
          />
        </Col>
      </Row>

    </div>
    </Modal>
  );
}

export default confirmable(AddPartDialog)
