import { Col, message, Row, Spin } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { showAddNotificationDialog } from "pages/NotificationPage/AddNotificationDialog/showAddNotificationDialog";
import React, { useEffect, useState } from 'react'
import { apiGetDeviceList, apiSendGroupNotification, apiSendOneNotification } from "services/deviceService";
import "./DevicePage.css"
import DeviceTableWidget from "./DeviceTableWidget/DeviceTableWidget";


function DevicePage() {
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetDeviceList()
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                // message.error("Sorry. " + err);
            });
    };

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.device_uuid.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onClickDelete = (pInfo) => {
        console.log("pInfo", pInfo)

    }
    const onSendOneNotification = async (pInfo) => {
        var res = await showAddNotificationDialog({
            title: `Send Notification to this device\n (${pInfo.device_uuid})`,
            isEditing: false
        });
        if (res === null) return;
        setIsBusy(true);
        apiSendOneNotification(pInfo.id, res.title, res.body)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while sending new notification");
                }
                message.success("Notifcation has been sent successfully.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while sending a notification " + err)
            });
    }
    const onSendGroupNotification = async () => {
        if (!hasSelected) {
            message.info("Please select device to send notification");
            return
        }
        var res = await showAddNotificationDialog({
            title: "Send New Notification",
            isEditing: false
        });
        if (res === null) return;
        apiSendGroupNotification(selectedRowKeys, res.title, res.body)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while sending new notification");
                }
                message.success("Notifcation has been sent successfully.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while sending a notification " + err)
            });
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>

                <div className="part-header">Device List
                    <div className="part-extra">
                        
                        <CommonButton onClick={() => {
                            onSendGroupNotification()
                        }}>Send  
                        <span >
                            {hasSelected ? ` to ${selectedRowKeys.length} devices` : ' Multi Notification'}
                        </span>
                        </CommonButton>
                    </div>
                </div>
                <div className="part-search">
                    <TitleSearch
                        placeholder="Search by Device Info"
                        onSearch={handleSearch}
                        onChange={handleChange}
                        onPressEnter={handleSearch}
                    />
                </div>

                <div>
                    <DeviceTableWidget
                        dataList={searchList}
                        setDataList={setSearchList}
                        onUpdateAction={onSendOneNotification}
                        onDeleteAction={onClickDelete}
                        rowSelection={rowSelection}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    );
}

export default DevicePage
