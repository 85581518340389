import { axiosDelete, axiosGet, axiosPost, axiosPostMultiPart, axiosPut } from "./ajaxServices";
import { urlGetProfileInfo, urlUpdateProfileInfo } from "./CONSTANTS";
export const apiGetProfileInfo = () => {
    const url = urlGetProfileInfo;
    return new Promise((resolve, reject) => {
      axiosGet(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
};
export function apiUpdateProfile(userData, new_image_url) {
    const url = urlUpdateProfileInfo;
    var formData = new FormData();
    formData.append("first_name", userData.first_name);
    formData.append("last_name", userData.last_name);
    formData.append("email", userData.email);
    if(userData.new_password !== undefined && userData.new_password !== ""){
      formData.append("old_password", userData.old_password);
      formData.append("new_password", userData.new_password);
    }
    if (new_image_url != undefined) {
      if (new_image_url instanceof File) {
        formData.append("new_image_url", new_image_url);
      }
    }
    return new Promise((resolve, reject) => {
      axiosPostMultiPart(url, formData)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
    
}
