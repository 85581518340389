import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import "./AddNotificationDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import { Col, Input, message, Row, Modal } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";

const { TextArea } = Input;

function AddNotificationDialog(props) {
  const { show, proceed, partInfo, title, isEditing } = props

  const [selPart, setSelPart] = useState({});
  const [errorField, setErrorField] = useState([]);

  const [partDescription, setPartDescription] = useState('');
  
  
  useEffect(() => {
    if (partInfo !== undefined) {
      setSelPart({ ...partInfo });
    } else {
      setSelPart({});
    }
  }, [partInfo]);

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(selPart, "title", errorList);
    errorList = isEmpty(selPart, "body", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-part-dlg"
      onOk={async () => {

        if (isEditing === false && (await validateFields()) === false) return;
        selPart["description"] = partDescription
        setSelPart({ ...selPart });
        proceed({
          ...selPart,
        });
      }}
    >
      <div>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div>Title*</div>
          <ProductDescriptionInput
            placeholder="Enter title"
            field={"title"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div>Body message*</div>
          <ProductDescriptionInput
            placeholder="Enter body message"
            field={"body"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>

    </div>
    </Modal>
  );
}

export default confirmable(AddNotificationDialog)
