import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
const { Option } = Select;

function TableWidget(props) {
    const { dataList, setDataList, onUpdateAction, onDeleteAction, rowSelection} = props;
    if (dataList === undefined) return <div />

    function createMarkup(html) {
        return { __html: html };
    }


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Title",
            dataIndex: "title",
            sorter: {
                compare: (a, b) => a.title.localeCompare(b.title),
            },
        },
        {
            title: "Body",
            dataIndex: "body",
            sorter: {
                compare: (a, b) => a.body.localeCompare(b.body),
            },
        },
        {
            title: "Device Info",
            dataIndex: "device_uuid",
            sorter: {
                compare: (a, b) => a.device_uuid.localeCompare(b.device_uuid),
            },
        },
        {
            title: "Created Date",
            dataIndex: "add_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {

                return <div size="middle" direction={"vertical"}>
                    <a className="edit-btn"
                        onClick={async() => {
                            var res = await showConfirmDlgWidget({ title: "Resend Confirmation", content: "Are you sure to resend this notification?" });
                            if (res == false) return;
                            onUpdateAction(record)
                        }}
                    >
                        Resend
                    </a>
                    <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                        var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                        if (res == false) return;
                        onDeleteAction(record)
                    }}>
                        Delete
                    </a>
                </div>
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
                rowSelection={rowSelection}
            />
        </div>
    )
}

export default withRouter(TableWidget)
