import { axiosGet, axiosPost } from "./ajaxServices";
import {  urlGetDeviceList, urlSendGroupNotification, urlSendOneNotification } from "./CONSTANTS";

export const apiGetDeviceList = () => {
  const url = urlGetDeviceList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export function apiSendOneNotification(id, title, body) {
  const url = urlSendOneNotification;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id",    id);
  formData.append("title", title);
  formData.append("body",  body);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export function apiSendGroupNotification(idList, title, body) {
  const url = urlSendGroupNotification;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("idList",    JSON.stringify(idList));
  formData.append("title", title);
  formData.append("body",  body);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}