import { axiosGet, axiosPost } from "./ajaxServices";
import {  urlDeleteNotification,  urlGetNotificationList,  urlResendNotification, urlSendNotification } from "./CONSTANTS";

export const apiGetNotificationList = () => {
  const url = urlGetNotificationList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiSendNotification(idList) {
    const url = urlSendNotification;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("idList",    JSON.stringify(idList));
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

export function apiResendNotification(data) {
    const url = urlResendNotification;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id",    data.id);
    formData.append("title", data.title);
    formData.append("body",  data.body);
    formData.append("device_uuid",  data.device_uuid);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  
  
  export function apiDeleteNotification(id) {
    const url = urlDeleteNotification;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", id);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }