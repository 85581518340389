
export const validateEmail = (email) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(email)) {
    return false;
  } else {
    return true;
  }
};
export const validatePassword = (password) => {
  if (password.length < 6) {
    return false;
  }
  return true;
};

export const calcDistance = (lat1, lng1, lat2, lng2) => {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lng2 - lng1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

function toRad(Value) {
  return (Value * Math.PI) / 180;
}

/*

///////////////////////////////////////////
  Delete Authorization field 
///////////////////////////////////////////
axios
.get(url, {
  crossDomain: true,
  transformRequest: (data, headers) => {
    delete headers.common["Authorization"];
  },
})
.then((res) => {
  const loc = res.data.result.geometry.location;
  resolve(loc);
})
.catch((err) => {
  reject({ lat: 0, lng: 0 });
});
*/
