import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Layout, Menu, Divider } from "antd";
import { MENU_ITEMS, } from "navigation/CONSTANTS";
import collapseIcon from '../../assets/icons/ic_collapse.svg'
import "./SidebarWidget.css";

import { IconWidget } from "components/IconWidget/IconWidget";

import { ChangeMenuCollapsed, UpdateCurrentRoot } from "redux/actions/appActions";
const { SubMenu } = Menu;

const SidebarWidget = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const appDataStore = useSelector(x => x.appDataStore);
    const { collapsed, width, currentRoute } = appDataStore;

    const menuUI = MENU_ITEMS.filter((x) => x.type == "sidebar").map((info, index) => {
        if (info.submenu !== undefined && info.submenu.length > 0) {
            let subMenuUI = info.submenu.filter((x) => x.type == "sidebar").map((subInfo, subIndex) => {
                return <Menu.Item className="c-menu-item" key={subInfo.route} icon={subInfo.icon} onClick={() => {
                    history.push(subInfo.route)
                    dispatch(ChangeMenuCollapsed({ collapsed: false }))
                }}>
                    {subInfo.title}
                </Menu.Item>
            })
            return <SubMenu className="c-menu-item" key={info.route} icon={info.icon} title={info.title}>
                {subMenuUI}
            </SubMenu>
        }
        return <Menu.Item className="c-menu-item" key={info.route} icon={info.icon} onClick={() => {
            history.push(info.route)
            dispatch(ChangeMenuCollapsed({ collapsed: false }))
        }}>
            {info.title}
        </Menu.Item>
    })
    const onToggleCollapsed = () => {
        dispatch(ChangeMenuCollapsed({ collapsed: !collapsed }))
    }

    return (
        <div>
            <div className="logo">
                <img
                    style={{ maxWidth: "80%", maxHeight: 200 }}
                    src={'/assets/images/logo.png'}
                    alt="YoConnectTester"
                />
                {
                    width > 1024 ?
                        <div className="side-collapse" onClick={() => { onToggleCollapsed(); }}>
                            <IconWidget Icon={collapseIcon} />
                        </div>
                        : <div></div>}
            </div>
            <Divider />
            <div className="layout-store-name"></div>
            <Menu
                mode="inline"
                defaultSelectedKeys={[currentRoute]}
                selectedKeys={[currentRoute]}
                className="c-menu"
                onSelect={(v) => {
                    dispatch(UpdateCurrentRoot({ currentRoute: v.key }))
                }}
            >
                {menuUI}
            </Menu>
            <Layout>
                {props.children}
            </Layout>
        </div>
    );
};

export default SidebarWidget;