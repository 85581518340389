import { axiosGet, axiosPost } from "./ajaxServices";
import { urlCreatePart, urlDeletePart, urlGetPartList, urlUpdatePart } from "./CONSTANTS";

export const apiGetPartList = () => {
  const url = urlGetPartList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiCreatePart(data) {
    const url = urlCreatePart;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("url",      data.url);
    formData.append("time_to_spend_on_site",  data.time_to_spend_on_site);
    formData.append("link_to_click_on",   data.link_to_click_on);
    formData.append("how_many_links_deep",     data.how_many_links_deep);
    formData.append("description",     data.description);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

export function apiUpdatePart(data) {
    const url = urlUpdatePart;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id",           data.id);
    formData.append("url",      data.url);
    formData.append("time_to_spend_on_site",  data.time_to_spend_on_site);
    formData.append("link_to_click_on",   data.link_to_click_on);
    formData.append("how_many_links_deep",     data.how_many_links_deep);
    formData.append("description",     data.description);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  
  
  export function apiDeletePart(id) {
    const url = urlDeletePart;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", id);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }