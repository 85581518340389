// @flow strict

import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "./InputEmailOrPhone.css";
import { Input, Radio } from "antd";
import { REGISTER_WITH } from "config/CONSTANTS";
function InputEmailOrPhone(props) {
  const { userDetail, setUserDetail } = props;
  const { wrongList, setWrongList } = props;
  return (
    <div>
      {/* <div className="register-with-switcher">
        <Radio.Group
          style={{ textAlign: "left", color: "var(--blackColor)" }}
          onChange={(e) =>
            setUserDetail({ ...userDetail, register_with: e.target.value })
          }
          value={userDetail.register_with}
        >
          <Radio style={{color: 'var(--blackColor)'}} value={REGISTER_WITH.email}>Email</Radio>
          <Radio style={{color: 'var(--blackColor)'}} value={REGISTER_WITH.phone}>Phone number</Radio>
        </Radio.Group>
      </div> */}

      <div
        className="email-input"
        style={{
          display:
            userDetail.register_with === REGISTER_WITH.email ? "block" : "none",
        }}
      >
        <Input
          type="email"
          placeholder="Enter your email"
          value={userDetail.email === undefined ? "" : userDetail.email}
          onChange={(e) =>
            setUserDetail({ ...userDetail, email: e.target.value })
          }
          style={{
            border:
              wrongList.includes("email") === true
                ? "1px solid var(--redColor)"
                : "1px solid var(--greyColor)",
          }}
        />
      </div>

      <div
        className="phone-number-input"
        style={{
          display:
            userDetail.register_with === REGISTER_WITH.phone ? "block" : "none",
        }}
      >
        <PhoneInput
          defaultCountry="US"
          placeholder="Enter your phone number"
          value={userDetail.phone === undefined ? "" : userDetail.phone}
          onChange={(e) => setUserDetail({ ...userDetail, phone: e })}
          className={
            wrongList.includes("phone") === true
              ? "phone-input-wrong"
              : "phone-input-correct"
          }
        />
      </div>
    </div>
  );
}

export default InputEmailOrPhone;
