// @flow strict

import React, { useState } from "react";
import "./LocationInputTextWidget.css";
import { Col, message, Row } from "antd";
import AuthDialogWidget from "components/AuthDialogWidget/AuthDialogWidget";
import AuthLogoWidget from "components/AuthLogoWidget/AuthLogoWidget";

import InputLocation from "components/InputWidgets/InputLocation/InputLocation";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
function LocationInputTextWidget(props) {
  const [location, setLocation] = useState(null);
  const { onSuccessLogin, userDetail } = props;

  const onClickSetLocation = () => {
    if (location === null) {
      message.error("Please select your address");
      return;
    }
    // console.log("location", location);
    // console.log("userDetail", userDetail);
    let tmpUser = userDetail;
    tmpUser.user["latitude"] = location.lat;
    tmpUser.user["longitude"] = location.lng;
    tmpUser.user["address"] = location.address;
    const param = {
      latitude: location.lat,
      longitude: location.lng,
      address: location.address,
    };
    onSuccessLogin(tmpUser);
  };
  return (
    <div style={{ color: "var(--blackColor)" }}>
      <Row align={"middle"}>
        <Col xs={24} sm={12} style={{ textAlign: "center" }}>
          <AuthLogoWidget />
        </Col>
        <Col xs={24} sm={12} style={{ textAlign: "center" }}>
          <AuthDialogWidget title={"Location"}>
            <div className="location-input-explain">
              Provide your location to find promotions and stores around you
            </div>
            <div style={{ marginTop: 20 }}>
              <InputLocation location={location} setLocation={setLocation} />
            </div>
            <div style={{ marginTop: 40, textAlign: "center" }}>
              <NormalButton
                onClick={onClickSetLocation}
                style={{ backgroundColor: "var(--blueColor)", maxWidth: 300 }}
              >
                Set Location
              </NormalButton>
            </div>
          </AuthDialogWidget>
        </Col>
      </Row>
    </div>
  );
}

export default LocationInputTextWidget;
