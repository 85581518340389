import { googleKey } from "config/CONSTANTS";

//export const BASE_URL               = "http://192.168.0.69/YoConnectTester";
export const BASE_URL               = "http://23.239.18.147/backend/YoConnectTester";

//Admin backend base url
export const BASE_URL_ADMIN         = BASE_URL + "/admin";

export const urlUserLogin           = BASE_URL_ADMIN + "/main/login";

export const urlGetPartList         = BASE_URL_ADMIN + "/Traffic/all";
export const urlUpdatePart          = BASE_URL_ADMIN + "/Traffic/updateTraffic";
export const urlDeletePart          = BASE_URL_ADMIN + "/Traffic/deleteTraffic";
export const urlCreatePart          = BASE_URL_ADMIN + "/Traffic/addTraffic";


export const urlGetProfileInfo      = BASE_URL_ADMIN + "/Profile/GetProfileInfo";
export const urlUpdateProfileInfo   = BASE_URL_ADMIN + "/Profile/UpdateProfileInfo";

export const urlGetDashboardDetail  = BASE_URL_ADMIN + "/DashBoard/GetDashboardDetail";

export const urlGetNotificationList = BASE_URL_ADMIN + "/Notification/all";
export const urlResendNotification  = BASE_URL_ADMIN + "/Notification/resendNotification";
export const urlSendNotification    = BASE_URL_ADMIN + "/Notification/sendNotification";
export const urlDeleteNotification  = BASE_URL_ADMIN + "/Notification/deleteNotification";

export const urlGetDeviceList       = BASE_URL_ADMIN + "/Device/all";
export const urlSendOneNotification = BASE_URL_ADMIN + "/Device/sendOneNotification";
export const urlSendGroupNotification = BASE_URL_ADMIN + "/Device/sendGroupNotification";

export const urlGenerateReport       = BASE_URL_ADMIN + "/Report/generateReport";

