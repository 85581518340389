import { Col, message, Row, Spin } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import React, { useEffect, useState } from 'react'
import { apiDeletePart, apiUpdatePart, apiGetPartList, apiCreatePart } from "services/partService";
import { showAddPartDialog } from "./AddPartDialog/showAddPartDialog";
import "./PartPage.css"
import PartTableWidget from "./PartTableWidget/PartTableWidget";


function PartPage() {
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetPartList()
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
            });
    };

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.url.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onClickDelete = (pInfo) => {
        console.log("pInfo", pInfo)
        setIsBusy(true);
        apiDeletePart(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
                setDataList([]);
                setSearchList([]);
            });
    }
    const onClickUpdate = async(pInfo) => {
        let info = pInfo
        var res = await showAddPartDialog({
          title: "Edit Traffic Info",
          partInfo: info,
          isEditing : true
        });
        if (res === null) return;
        setIsBusy(true);
        apiUpdatePart(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while updating a Traffic Info");
                }
                let updateData = [...dataList];
                var storeIndex = updateData.findIndex(x => x.id == data[0].id);
                updateData.splice(storeIndex, 1, data[0]);
                setDataList(updateData);
                setSearchList(updateData)
                message.success("Traffic Info has been updated.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while updating a Traffic. " + err);
                setDataList([]);
                setSearchList([]);
            });
    }
    const onClickNew = async () => {
        var res = await showAddPartDialog({
            title: "Create New Traffic",
            isEditing: false
        });
        if (res === null) return;
        setIsBusy(true);
        apiCreatePart(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while creating a Traffic");
                }
                let updateData = [...dataList];
                updateData = [...data, ...updateData];
                setDataList(updateData);
                setSearchList(updateData)
                message.success("New Traffic has been created.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while creating a Traffic" + err)
            });

    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>

                <div className="part-header">Traffic Info List
                    <div className="part-extra">
                        <CommonButton onClick={() => {
                            onClickNew()
                        }}>New Traffic</CommonButton>
                    </div>
                </div>
                <div className="part-search">
                    <TitleSearch
                        placeholder="Search by Url"
                        onSearch={handleSearch}
                        onChange={handleChange}
                        onPressEnter={handleSearch}
                    />
                </div>

                <div>
                    <PartTableWidget
                        dataList={searchList}
                        setDataList={setSearchList}
                        onUpdateAction={onClickUpdate}
                        onDeleteAction={onClickDelete}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    );
}

export default PartPage
