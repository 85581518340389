import React from 'react';
import './CommonButton.css'
const CommonButton = (props) => {
    const {onClick, customClass, style } = props;
    return (
        <span className={`custom-button common-button ${customClass === undefined ? "" : customClass}`} style={style === undefined ? {} : style} onClick={onClick}>
            {props.children}
        </span>
    );
};

export default CommonButton;