import { MENU_COLLAPSED_CHANGED, SIZE_CHANGED, CURRENT_ROUTE } from "redux/actions/appActions";

const initialState = {
  width: 1024,
  height: 768,
  collapsed: false,
  currentRoute: '/dashboard'
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIZE_CHANGED:
      return {
        ...state,
        ...action.payload
      }
    case MENU_COLLAPSED_CHANGED:
      return {
        ...state,
        ...action.payload
      }
    case CURRENT_ROUTE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
};
