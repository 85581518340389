import { withRouter } from "react-router-dom";

function ForgotPasswordPage(props) {
  return (
  <div>
  ForgotPasswordPage
  </div>
  )
}

export default withRouter(ForgotPasswordPage);
