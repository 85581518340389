import { Col, message, Row, Spin } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import React, { useEffect, useState } from 'react'
import { apiDeleteNotification, apiGetNotificationList, apiResendNotification, apiSendNotification } from "services/notificationService";
import { showAddNotificationDialog } from "./AddNotificationDialog/showAddNotificationDialog";
import "./NotificationPage.css"
import NotificationTableWidget from "./NotificationTableWidget/NotificationTableWidget";


function NotificationPage() {
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);


    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;


    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetNotificationList()
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                // message.error("Sorry. " + err);
            });
    };

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.title.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onClickDelete = (pInfo) => {
        setIsBusy(true);
        apiDeleteNotification(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
                setDataList([]);
                setSearchList([]);
            });
    }
    const onClickUpdate = async (pInfo) => {
        setIsBusy(true);
        apiResendNotification(pInfo)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while sending new notification");
                }
                let updateData = [...dataList];
                updateData = [...data, ...updateData];
                setDataList(updateData);
                setSearchList(updateData)
                message.success("Notifcation has been sent successfully.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while sending a notification " + err)
               
            });
    }
    const onClickNew = async () => {
        if (!hasSelected) {
            message.info("Please select anyone to send notification");
            return
        }
        var res = await showConfirmDlgWidget({ title: "Resend Confirmation", content: "Are you sure to resend this notification?" });
        if (res == false) return;
        apiSendNotification(selectedRowKeys)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while sending new notification");
                }
                let updateData = [...dataList];
                updateData = [...data, ...updateData];
                setDataList(updateData);
                setSearchList(updateData)
                message.success("Notifcation has been sent successfully.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while sending a notification " + err)
            });
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>

                <div className="part-header">Notification History
                    <div className="part-extra">
                        <CommonButton onClick={() => {
                            onClickNew()
                        }}>Resend <span >
                                {hasSelected ? ` to ${selectedRowKeys.length} devices` : 'Multi Notification'}
                            </span></CommonButton>
                    </div>
                </div>
                <div className="part-search">
                    <TitleSearch
                        placeholder="Search by title"
                        onSearch={handleSearch}
                        onChange={handleChange}
                        onPressEnter={handleSearch}
                    />
                </div>

                <div>
                    <NotificationTableWidget
                        dataList={searchList}
                        setDataList={setSearchList}
                        onUpdateAction={onClickUpdate}
                        onDeleteAction={onClickDelete}
                        rowSelection={rowSelection}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    );
}

export default NotificationPage
