import React from "react";
import { Row, Spin, Col, DatePicker, Space, message, Radio } from 'antd';
import "./ReportPage.css";
import { useState } from "react";
import { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import moment from "moment"
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import { apiGenerateReport } from "services/reportService";
import TableWidget from "./TableWidget/TableWidget";
const { RangePicker } = DatePicker;

function ReportPage() {
    const [isBusy, setIsBusy] = useState(false);
    const [data, setData] = useState({"start_date" : moment(new Date()).format("YYYY-MM-DD"), "end_date" : moment(new Date()).format("YYYY-MM-DD")})
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    useEffect(() => {
        onGenerateReport()
    }, []);

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };

    function onChangeDatePicker(date, dateString) {
        var tmpData = data
        tmpData["start_date"] = dateString[0]
        tmpData["end_date"] = dateString[1]
        setData({ ...tmpData })
    }

    const onGenerateReport = () => {
        if (data["start_date"] === undefined || data["end_date"] === undefined) {
            message.info("Please select date")
            return
        }
        setIsBusy(true);
        apiGenerateReport(data)
            .then((res) => {
                setIsBusy(false);
                setDataList(res)
                setSearchList(res)
            })
            .catch((err) => {
                setIsBusy(false);
                setDataList([])
                setSearchList([])
                message.error(err);
            });
    }
    

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row>
                    <Col>
                        <div style={{ fontFamily: 'mediumFont', padding: 10 }}>Date Filter Period : &nbsp;&nbsp;
                            <RangePicker
                                defaultValue={[
                                    moment(),
                                    moment()
                                ]}
                                ranges={{
                                "Today": [moment(), moment()],
                                "This Week": [moment().startOf("week"), moment().endOf("week")],
                                "This Month": [
                                    moment().startOf("month"),
                                    moment().endOf("month"),
                                ],
                                "This Quarter": [
                                    moment().startOf("quarter"),
                                    moment().endOf("quarter"),
                                ],
                                "This Year": [
                                    moment().startOf("year"),
                                    moment().endOf("year"),
                                ],
                            }} onChange={onChangeDatePicker} />
                        </div>
                    </Col>
                    <Col flex={'auto'}>
                        <div style={{ fontFamily: 'mediumFont', padding: 10 }}>
                            <TitleSearch
                                onSearch={handleSearch}
                                onChange={handleChange}
                                onPressEnter={handleSearch}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{ fontFamily: 'mediumFont', padding: 10 }}>
                            <CommonButton onClick={() => {
                                onGenerateReport()
                            }}>Generate</CommonButton>
                        </div>
                    </Col>
                </Row>
                <div >
                    <TableWidget
                        dataList={searchList}
                    />
                </div>
            </Spin>
        </CommonDivWidget>

    );
}

export default ReportPage;
